<template>
    <span>Ide nes</span>
        
</template>

<script>
export default {
    name: 'grid-row-index',

    data: function() {
        return {
            data: {},
        }
    },
}
</script>
