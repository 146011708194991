<template>


<div class="p-3">

    <h3>Karnet</h3>

    <div class="m-0 d-flex flex-wrap" role="group" aria-label="Basic radio toggle button group">
        <div class="type-select">
            <input v-model="carnetView" value="MONTH" type="radio" class="btn-check" name="btnradio" id="btn-month-view" autocomplete="off" checked @change="changedCarnetType">
            <label class="btn btn-outline-primary btn-sm type-select-lable" for="btn-month-view">Mesečni prikaz</label>
        </div>
        <div class="type-select">
            <input v-model="carnetView" value="YEAR" type="radio" class="btn-check" name="btnradio" id="btn-year-view" autocomplete="off" @change="changedCarnetType">
            <label class="btn btn-outline-primary btn-sm type-select-lable" for="btn-year-view">Godišnji prikaz</label>
        </div>

    </div>

    <div v-if="carnetView === 'MONTH'" class="form-floating">
        <input type="month" class="form-control" id="woEndDate" v-model="selectedDate" placeholder="Period" @change="changeDate" min="2021-07" :max="currentMoment.format('YYYY-MM')" required>
        <label for="woEndDate">Mesec</label>
        <div class="invalid-feedback">
            Unesite period
        </div>
    </div>
    

    <div v-else-if="carnetView == 'YEAR'" class="form-floating">
        <select id="dob" class="form-control" @change="changeYear" v-model="selectedYear">
            <option value="0">Year:</option>
            <option v-for="year in years" :key=year :value="year">{{ year }}</option>
        </select>     
        <label for="woEndDate">Godina</label>
        <div class="invalid-feedback">
            Unesite period
        </div>
    </div>

    <div class="col-lg-12 control-section">

        <div v-if="carnetView==='MONTH'">
            <ejs-grid
                ref="empCarnetGrid"
                :dataSource="employeeCarnet" 
                class="emp-carnet-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :allowSelection='false'
                :groupSettings='groupOptions'
                :filterSettings='filterSettings'
                :rowDataBound='rowDataBound'
                gridLines='Both'
                :created='setHeaderHeight'
                :enablePersistence='false'
                height="500"
                :allowResizing='true'
                :toolbar='toolbar'
                :toolbarClick='clickHandler'
                :allowExcelExport='true'
                :allowPdfExport='true'
                :actionFailure='actionFailure'
                >
                <e-columns>
                    <e-column field='date' headerText='Datum' width='120' textAlign='Left' :isPrimaryKey='true' :visible='true' :valueAccessor="dateAccessor" ></e-column>
                    <e-column field='working_day' clipMode='Clip' headerText='Radni dan' width='55' :customAttributes="vertColumnAttribute" textAlign="Center" :displayAsCheckBox="true" type="boolean"></e-column>
                    <e-column field='time_start' headerText='Dolazak' width='80' :customAttributes="vertColumnAttribute" :valueAccessor="hoursAccessor" textAlign="Center"></e-column>
                    <e-column field='time_end' headerText='Odlazak' width='80' :customAttributes="vertColumnAttribute" :valueAccessor="hoursAccessor" textAlign="Center"></e-column>
                    <e-column field='work_hours' headerText='Radnih sati' width='130' :customAttributes="vertColumnAttribute" :valueAccessor="workHoursAccessor" textAlign="Center"></e-column>
                    <e-column field='not_appeared' headerText='Neopravdani' clipMode='Clip' width='55' :customAttributes="vertColumnAttribute" textAlign="Center" :displayAsCheckBox="true" type="boolean"></e-column>
                    <e-column field='catering_own' headerText='Samostalni obrok' width='55' clipMode='Clip' :customAttributes="vertColumnAttribute" textAlign="Center" :displayAsCheckBox="true" type="boolean"></e-column>
                    <e-column field='catering_service' headerText='Ketering' width='55' clipMode='Clip' :customAttributes="vertColumnAttribute" textAlign="Center" :displayAsCheckBox="true" type="boolean"></e-column>
                    <e-column headerText='Odsustva' :columns='absenceColumns' :displayAsCheckBox="true" type="boolean"></e-column>

                </e-columns>
                <e-aggregates>
                    <e-aggregate>
                        <e-columns>
                            <e-column type="Count" field="date"></e-column>
                            <e-column type="Sum" field="working_day"></e-column>
                            <e-column type="Sum" field="work_hours"></e-column>
                            <e-column type="Custom" field="attendance.time_start" :customAggregate="customAggregateCount"></e-column>
                            <e-column type="Sum" field="not_appeared"></e-column>
                            <e-column type="Sum" field="catering_own"></e-column>
                            <e-column type="Sum" field="catering_service"></e-column>
                            <e-column type="Sum" field="absence.vacation" ></e-column>
                            <e-column type="Sum" field="absence.sickness" ></e-column>
                            <e-column type="Sum" field="absence.other" ></e-column>
                            <e-column type="Sum" field="absence.total" ></e-column>
                        </e-columns>
                    </e-aggregate>
                </e-aggregates>
            </ejs-grid> 
        </div>

        <employee-carnet-months ref="carnetMonths" :employee="employee" :year="selectedYear" v-if="carnetView==='YEAR'" />

    </div>


</div>
</template>

<script>
import EmployeesService from '@/service/EmployeesService';
import AgregateSumTemplate from '@/components/grid-templates/AgregateSum.vue';



import UtilService from '@/service/UtilService.js';
import { Sort, ColumnMenu, Filter, Group, Edit, Aggregate, Resize, Toolbar, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";
import EmployeeCarnetMonths from './EmployeeCarnetMonths.vue';

export default {
  components: { EmployeeCarnetMonths },

    name: "EmployeeAbsences",
    props: ['employee'],

    provide: {
        grid: [Sort, ColumnMenu, Filter, Group, Edit, Aggregate, Resize, Toolbar, PdfExport, ExcelExport ]
    },

    data() {
        return {
            user: {},
        
            employeeCarnet: [],
            groupOptions: { showGroupedColumn: true },
            filterSettings: { type: "Excel" },

            carnetView: "MONTH",
            selectedYear: UtilService.formatDatePattern(UtilService.getMoment(), "yyyy"),

            absenceColumns: [
                {
                    field: 'absence.vacation',
                    headerText: 'Odmor',
                    width: 50,
                    textAlign: 'Right',
                    minWidth: 10,
                    displayAsCheckBox: "true",
                    type: "boolean",
                    visible: true,
                    clipMode: 'Clip',
                    customAttributes: {class : 'orientationcss'},
                },
                {
                    field: 'absence.sickness',
                    headerText: 'Bolovanje',
                    width: 55,
                    textAlign: 'Right',
                    minWidth: 10,
                    displayAsCheckBox: "true",
                    type: "boolean",
                    visible: true,
                    clipMode: 'Clip',
                    customAttributes: {class : 'orientationcss'},
                },
                {
                    field: 'absence.sickness',
                    headerText: 'Bolovanje',
                    width: 55,
                    textAlign: 'Right',
                    minWidth: 10,
                    displayAsCheckBox: "true",
                    type: "boolean",
                    visible: true,
                    clipMode: 'Clip',
                    customAttributes: {class : 'orientationcss'},
                },
                {
                    field: 'absence.other',
                    headerText: 'Drugo',
                    width: 55,
                    textAlign: 'Right',
                    minWidth: 10,
                    displayAsCheckBox: "true",
                    type: "boolean",
                    visible: true,
                    clipMode: 'Clip',
                    customAttributes: {class : 'orientationcss'},
                },
                {
                    field: 'absence.total',
                    headerText: 'Sva odsustva',
                    width: 55,
                    textAlign: 'Right',
                    minWidth: 10,
                    displayAsCheckBox: "true",
                    type: "boolean",
                    visible: true,
                    clipMode: 'Clip',
                    customAttributes: {class : 'orientationcss'},
                }
            ],

            toolbar: [

                { text: 'Exprot Excel', tooltipText: 'Izvezi Excel', prefixIcon: 'e-excelexport', id: 'exportExcel', align: 'Right'},
                { text: 'Exprot PDF', tooltipText: 'Izvezi PDF', prefixIcon: 'e-pdfexport', id: 'exportPDF', align: 'Right'},
                { text: 'Print', tooltipText: 'Štampaj', prefixIcon: 'e-print', id: 'print', align: 'Right'},
                ],

            sumTemplate() {
                return {
                    template: AgregateSumTemplate,
                }
            },
            vertColumnAttribute : {class : 'orientationcss'},

            selectedDate: UtilService.formatDatePattern(UtilService.getMoment(), "YYYY-MM"),

            pivotHeight: 600,
        }
    },



    created() {
        this.loadUserCarnet();

    },

    mounted() {
        console.log(this.$refs.empCarnetGrid);
    },

    computed: {
        currentMoment() {
            return UtilService.getMoment();
        },

        years () {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2019}, (value, index) => 2020 + index)
        },

    },
    


    methods: {
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,

        formatDateWithWeek: UtilService.formatDateWithWeek,

        dateAccessor(field, data) {
            return this.formatDateWithWeek(data['date']);
        },

        hoursAccessor(field, data) {
            try {
                let obj = data[field];                
                return obj.slice(0,5)
            }
            catch (error) {
                return '';
            }
        },

        workHoursAccessor(field, data) {
            return data[field] > 0 ? data[field] : '';
        },

        customAggregateCount(data) {
            if (Array.isArray(data))
                return data.filter((item) => item.work_hours > 0 ).length;

            return data.result.filter((item) => item.work_hours > 0 ).length;
        },

        rowDataBound(args) {
            if (args.data?.working_day == 0)
                args.row.classList.add('non-working-day-row');
            
            if (args.data?.not_appeared == true)
                args.row.classList.add('not-appeared-row');
        },

        setHeaderHeight() {
            let textWidth = document.querySelector(".orientationcss > div").scrollWidth;//Obtain the width of the headerText content.
            let headerCell = document.querySelectorAll(".e-headercell");
            for (let i = 0; i < headerCell.length; i++) {
                (headerCell.item(i)).style.height = textWidth + 'px'; //Assign the obtained textWidth as the height of the headerCell.
            }
        },

        async loadUserCarnet() {
            try {
                let result = await EmployeesService.getEmployeeCarnetDays({employee_id : this.employee.id, 
                    start_date: this.getStartDate().format("YYYY-MM-DD"), 
                    end_date: this.getEndDate().format("YYYY-MM-DD")})

                result.data.forEach(dayData => {
                    dayData.time_start = dayData?.attendance?.timesheet?.filter(x => !x.deleted)?.[0]?.time_start
                    dayData.time_end = dayData?.attendance?.timesheet?.filter(x => !x.deleted)?.at(-1)?.time_end
                });
                this.employeeCarnet = result.data;

            } catch (error) {
                console.log(error);
            }
        },

        changedCarnetType() {
            // alert("changed")
        },

        changeDate() {
            this.loadUserCarnet();
        },

        changeYear() {
            this.$refs.carnetMonths.loadUserCarnet(this.selectedYear);
        },


        getStartDate() {
            return UtilService.convertMoment(this.selectedDate);
        },


        getEndDate() {
            return this.getStartDate().add(1, 'month');
        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 3000
            });
        },

        clickHandler(args) {

            switch (args.item.id) {
                case 'exportExcel':
                    this.$refs.empCarnetGrid.excelExport();
                    break;
                case 'exportPDF':
                    this.$refs.empCarnetGrid.pdfExport();
                    break;
                case 'print':
                    this.$refs.empCarnetGrid.print();
                    break;
            
                default:
                    break;
            }

        },

        actionFailure(arg) {
            console.log(arg);
        }

    },
}
</script>


<style>
    .emp-carnet-grid table tr.non-working-day-row td {
        background: rgb(216, 216, 216);
    }
    .emp-carnet-grid table tr.non-working-day-row:hover td.e-rowcell {
        background: rgb(199, 199, 199) !important;
    }

    .emp-carnet-grid table tr.not-appeared-row td {
        background: rgb(255, 104, 104);
    }
    .emp-carnet-grid table tr.not-appeared-row:hover td.e-rowcell {
        background: rgb(255, 80, 80) !important;
    }

    .emp-carnet-grid .orientationcss .e-headercelldiv {

        width: 100px;
        margin-left: -40px !important;
    }

    .emp-carnet-grid .e-summaryrow .e-summarycell {

        padding-left: 0.7em;
        padding-right: 0.7em;
        text-align: center !important;
    }

    .emp-carnet-grid .e-footerpadding {
        padding-right: 14px;
        text-align: center;
    }

    .emp-carnet-grid .e-checkbox-disabled .e-frame.e-icons:not(.e-check) {
        display: none !important;
    }

    .emp-carnet-grid .e-headercontent .e-table {
        height: 200px;
    }

    
</style>