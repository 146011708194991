<template>
    <div>
        <ejs-grid
            ref="empCarnetMonthsGrid"
            :dataSource="employeeCarnet" 
            class="emp-carnet-grid"
            :allowFiltering='true'
            :allowSorting='true'
            :showColumnMenu='true'
            :allowSelection='false'
            :groupSettings='groupOptions'
            :filterSettings='filterSettings'
            :rowDataBound='rowDataBound'
            gridLines='Both'
            :created='setHeaderHeight'
            :enablePersistence='false'
            height="500"
            :allowResizing='true'
            :toolbar='toolbar'
            :toolbarClick='clickHandler'
            :allowExcelExport='true'
            :allowPdfExport='true'
            :actionFailure='actionFailure'
            >
            <e-columns>
                <e-column field='month' headerText='Mesec' width='120' textAlign='Left' :isPrimaryKey='true' :visible='true' :valueAccessor="monthAccessor" ></e-column>
                <e-column field='working_days' clipMode='Clip' headerText='Radnih dana' width='55' type="number" :customAttributes="vertColumnAttribute" :valueAccessor="zeroEmptyAccessor" textAlign="Center"></e-column>
                <e-column field='arrive_days' clipMode='Clip' headerText='Dolazaka' width='55' type="number" :customAttributes="vertColumnAttribute" :valueAccessor="zeroEmptyAccessor" textAlign="Center"></e-column>
                <e-column field='work_hours' headerText='Radnih sati' width='130' type="number" :customAttributes="vertColumnAttribute" :valueAccessor="workHoursAccessor" textAlign="Center"></e-column>
                <e-column field='not_appeared' headerText='Neopravdanih' clipMode='Clip' width='55' type="number" :customAttributes="vertColumnAttribute" :valueAccessor="zeroEmptyAccessor" textAlign="Center"></e-column>
                <e-column field='catering_own' headerText='Samostalni obrok' width='55' type="number" clipMode='Clip' :customAttributes="vertColumnAttribute" :valueAccessor="zeroEmptyAccessor" textAlign="Center"></e-column>
                <e-column field='catering_service' headerText='Ketering' width='55' type="number" clipMode='Clip' :customAttributes="vertColumnAttribute" :valueAccessor="zeroEmptyAccessor" textAlign="Center"></e-column>
                <e-column headerText='Odsustva' :columns='absenceColumns'></e-column>

            </e-columns>
            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Count" field="date"></e-column>
                        <e-column type="Sum" field="working_days"></e-column>
                        <e-column type="Sum" field="arrive_days"></e-column>
                        <e-column type="Sum" field="work_hours"></e-column>
                        <e-column type="Sum" field="not_appeared"></e-column>
                        <e-column type="Sum" field="catering_own"></e-column>
                        <e-column type="Sum" field="catering_service"></e-column>
                        <e-column type="Sum" field="absence.vacation" ></e-column>
                        <e-column type="Sum" field="absence.sickness" ></e-column>
                        <e-column type="Sum" field="absence.other" ></e-column>
                        <e-column type="Sum" field="absence.total" ></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid> 
    </div>
</template>

<script>
import EmployeesService from '@/service/EmployeesService';
import AgregateSumTemplate from '@/components/grid-templates/AgregateSum.vue';



import UtilService from '@/service/UtilService.js';
import { Sort, ColumnMenu, Filter, Group, Edit, Aggregate, Resize, Toolbar, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";

export default {

    name: "EmployeeAbsences",
    props: ['employee', 'year'],

    provide: {
        grid: [Sort, ColumnMenu, Filter, Group, Edit, Aggregate, Resize, Toolbar, PdfExport, ExcelExport ]
    },

    data() {
        return {
            user: {},
        
            employeeCarnet: [],
            groupOptions: { showGroupedColumn: true },
            filterSettings: { type: "Excel" },

            absenceColumns: [
                {
                    field: 'absence.vacation',
                    headerText: 'Odmor',
                    width: 50,
                    type: 'number',
                    textAlign: 'Right',
                    minWidth: 10,
                    displayAsCheckBox: "true",
                    visible: true,
                    clipMode: 'Clip',
                    valueAccessor: this.zeroEmptyAccessor,
                    customAttributes: {class : 'orientationcss'},
                },
                {
                    field: 'absence.sickness',
                    headerText: 'Bolovanje',
                    width: 55,
                    type: 'number',
                    textAlign: 'Right',
                    minWidth: 10,
                    visible: true,
                    clipMode: 'Clip',
                    valueAccessor: this.zeroEmptyAccessor,
                    customAttributes: {class : 'orientationcss'},
                },
                // {
                //     field: 'absence.religious',
                //     headerText: 'Verski Praznik',
                //     width: 55,
                //     type: 'number',
                //     textAlign: 'Right',
                //     minWidth: 10,
                //     visible: true,
                //     clipMode: 'Clip',
                //     valueAccessor: this.zeroEmptyAccessor,
                //     customAttributes: {class : 'orientationcss'},
                // },
                {
                    field: 'absence.other',
                    headerText: 'Drugo',
                    width: 55,
                    type: 'number',
                    textAlign: 'Right',
                    minWidth: 10,
                    visible: true,
                    clipMode: 'Clip',
                    valueAccessor: this.zeroEmptyAccessor,
                    customAttributes: {class : 'orientationcss'},
                },
                {
                    field: 'absence.total',
                    headerText: 'Sva odsustva',
                    width: 55,
                    type: 'number',
                    textAlign: 'Right',
                    minWidth: 10,
                    visible: true,
                    clipMode: 'Clip',
                    valueAccessor: this.zeroEmptyAccessor,
                    customAttributes: {class : 'orientationcss'},
                }
            ],

            toolbar: [

                { text: 'Exprot Excel', tooltipText: 'Izvezi Excel', prefixIcon: 'e-excelexport', id: 'exportExcel', align: 'Right'},
                { text: 'Exprot PDF', tooltipText: 'Izvezi PDF', prefixIcon: 'e-pdfexport', id: 'exportPDF', align: 'Right'},
                { text: 'Print', tooltipText: 'Štampaj', prefixIcon: 'e-print', id: 'print', align: 'Right'},
                ],

            sumTemplate() {
                return {
                    template: AgregateSumTemplate,
                }
            },
            vertColumnAttribute : {class : 'orientationcss'},

            selectedDate: UtilService.formatDatePattern(UtilService.getMoment(), "YYYY-MM"),

            pivotHeight: 600,
        }
    },



    created() {
        this.loadUserCarnet();
    },

    mounted() {
        console.log(this.$refs.empCarnetGrid);
    },

    computed: {
        currentMoment() {
            return UtilService.getMoment();
        },

    },
    


    methods: {
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,

        formatDateWithWeek: UtilService.formatDateWithWeek,

        monthAccessor(field, data) {
            return UtilService.months[data['month']];
        },

        hoursAccessor(field, data) {
            try {
                let obj = data[field];                
                return obj.slice(0,5)
            }
            catch (error) {
                return '';
            }
        },

        workHoursAccessor(field, data) {
            return data[field] > 0 ? data[field] : '';
        },

        zeroEmptyAccessor(field, data) {
            let token = field.split(".");
            let value = data;
            token.forEach(element => {
                value = value[element]
            });
            return value > 0 ? value : '';
        },

        customAggregateCount(data) {
            if (Array.isArray(data))
                return data.filter((item) => item.work_hours > 0 ).length;

            return data.result.filter((item) => item.work_hours > 0 ).length;
        },

        rowDataBound(args) {
            if (args.data?.working_day == 0)
                args.row.classList.add('non-working-day-row');
        },

        setHeaderHeight() {
            let textWidth = document.querySelector(".orientationcss > div").scrollWidth;//Obtain the width of the headerText content.
            let headerCell = document.querySelectorAll(".e-headercell");
            for (let i = 0; i < headerCell.length; i++) {
                (headerCell.item(i)).style.height = textWidth + 'px'; //Assign the obtained textWidth as the height of the headerCell.
            }
        },

        async loadUserCarnet(year) {
            if (!year) year = this.year
            // this.employeeCarnet = []
            this.$refs.empCarnetMonthsGrid?.showSpinner()
            try {
                let result = await EmployeesService.getEmployeeCarnetMonths({employee_id : this.employee.id, 
                    start_date: this.getStartDate(year), 
                    end_date: this.getEndDate(year)})

                result.data.forEach(dayData => {
                    dayData.time_start = dayData?.attendance?.timesheet?.[0]?.time_start
                    dayData.time_end = dayData?.attendance?.timesheet?.at(-1)?.time_end
                });
                this.employeeCarnet = result.data;

            } catch (error) {
                console.log(error);
            }
            this.$refs.empCarnetMonthsGrid?.hideSpinner()

        },

        changeDate() {
            this.loadUserCarnet();
        },

        getStartDate(year) {
            return year + "-01-01"
        },


        getEndDate(year) {
            return year + "-12-31"
        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 3000
            });
        },

        clickHandler(args) {
            let excelProperties = {
                fileName: `karnet_${ this.employee.user.username }_${this.year}.xlsx`,
            };
            switch (args.item.id) {
                case 'exportExcel':
                    this.$refs.empCarnetMonthsGrid.excelExport(excelProperties);
                    break;
                case 'exportPDF':
                    this.$refs.empCarnetMonthsGrid.pdfExport();
                    break;
                case 'print':
                    this.$refs.empCarnetMonthsGrid.print();
                    break;
            
                default:
                    break;
            }

        },

        actionFailure(arg) {
            console.log(arg);
        }

    },
}
</script>


<style>
    .emp-carnet-grid table tr.non-working-day-row td {
        background: rgb(216, 216, 216);
    }
    .emp-carnet-grid table tr.non-working-day-row:hover td.e-rowcell {
        background: rgb(199, 199, 199) !important;
    }

    .emp-carnet-grid table tr.not-appeared-row td {
        background: rgb(255, 104, 104);
    }
    .emp-carnet-grid table tr.not-appeared-row:hover td.e-rowcell {
        background: rgb(255, 80, 80) !important;
    }

    .emp-carnet-grid .orientationcss .e-headercelldiv {

        width: 100px;
        margin-left: -40px !important;
    }

    .emp-carnet-grid .e-summaryrow .e-summarycell {

        padding-left: 0.7em;
        padding-right: 0.7em;
        text-align: center !important;
    }

    .emp-carnet-grid .e-footerpadding {
        padding-right: 14px;
        text-align: center;
    }

    .emp-carnet-grid .e-checkbox-disabled .e-frame.e-icons:not(.e-check) {
        display: none !important;
    }

    .emp-carnet-grid .e-headercontent .e-table {
        height: 200px;
    }

    
</style>